export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      backgroundColor: 'transparent',
      // borderBottom: '2px solid',
      // borderColor: 'secondary',
      padding: ['0.5rem', '0.5rem', '1rem', '1rem 2rem'],
      zIndex: '1001'
    },

    '.containerScrolled': {
      backgroundColor: 'white',
      borderBottom: '1px solid',
      borderColor: 'secondary',
      zIndex: '1001',

      '.smallNavMenu': {
        '.navItem': {
          a: {
            color: 'black !important',
            textShadow: 'none !important'
          }
        }
      }
    },

    '.smallNavMenu': {
      border: 'none',
      '.navItem': {
        padding: '1rem',
        a: {
          color: 'secondary',
          textShadow: '2px 2px 8px black',
          fontFamily: 'body',
          fontSize: ['0.9rem', '0.9rem', '0.9rem', '0.9rem'],
          fontWeight: '500'
        }
      }
    },

    '.navItem': {
      margin: '0rem',
      padding: ['1.5rem 1rem', '1.5rem 1rem', '1.5rem 1rem'],
      a: {
        color: 'text'
      },
      ':hover': {
        opacity: '0.8'
      }
    },

    '.navMenuLogo': {
      position: 'static',
      padding: ['0.5rem', '', '2rem'],
      borderBottom: '1px solid',
      borderColor: 'secondary',
      margin: '0rem 0rem 2rem',
      img: {
        maxHeight: ['40px', '', '75px', '']
      }
    },
    '.navMenuOpen': {
      backgroundColor: 'background',
      width: ['85%', '', '40%'],
      justifyContent: ['flex-start', 'flex-start', 'flex-start'],
      alignItems: ['flex-start'],
      padding: ['1rem', '2rem'],
      '.seperatorLine': {
        width: '100%',
        maxWidth: 'unset',
        borderColor: 'secondary',
        margin: '1rem 0rem 0rem'
      },
      '.navItem': {
        padding: ['0.75rem', '0.75rem', '1rem', '0.75rem', '0.75rem', '1rem'],
        a: {
          fontSize: ['1rem', '1.25rem', '1.5rem', '1.75rem']
        }
      }
    },
    '.navBlockOpen': {
      position: 'fixed',
      left: '0rem',
      top: '0rem',
      width: '100%',
      height: '100vh',
      backdropFilter: 'blur(2px)',
      backgroundColor: 'rgb(30 24 6 / 70%)'
    },
    '.phoneSocialContainer': {
      width: '100%',
      maxWidth: 'unset',
      padding: '1rem 0rem'
    },
    '.socialIconsContainer, .phoneContainer': {
      '.smallNavHeading': {
        fontSize: ['1.1rem', '1.5rem'],
        textAlign: 'left'
      },
      a: {
        alignItems: 'flex-start',
        justifyContent: 'flex-start'
      },
      margin: '0rem',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      color: 'text',
      border: 'none',
      width: '100%',
      fontSize: '1rem',
      maxWidth: 'unset',
      svg: {
        path: {
          fill: 'text'
        }
      }
    },

    '.hamburger > div': {
      backgroundColor: 'primary'
    },
    '.hamburgerOpen > div': {
      backgroundColor: 'text'
    },
    '.logoLocationContainer': {
      '.logoScrolled': {
        a: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }
      }
    },
    '.logo': {
      padding: '1rem 0rem',
      img: {
        opacity: '1',
        maxHeight: ['45px', '60px'],
        filter: 'unset'
      }
    },
    '.logoScrolled': {
      padding: '0rem',

      img: {
        opacity: '1',
        maxHeight: ['35px', '40px']
      }
    },
    '.hamburger': {
      display: ['', '', '', 'none']
    }
  },

  footer: {
    backgroundColor: 'background',
    borderTop: 'solid 2px',
    borderColor: 'primary',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    padding: ['2rem 0.5rem 6rem', '', '2rem 3rem'],
    color: 'text',
    '.image': {
      // filter: 'brightness(0) invert(1)',
      // display: 'none',
      maxHeight: '50px'
    },
    '.multiButtonContainer': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      a: {
        width: 'fit-content',
        fontFamily: 'heading',
        color: 'text'
      }
    },
    '.contactDetails-container': {
      display: 'none'
    },
    '.copyright': {
      width: '50%',
      color: 'text',
      textAlign: 'center'
    },
    '.socialIconsContainer': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '1rem',
      a: {
        svg: {
          width: '40px',
          height: '40px',
          padding: '0.5rem',
          border: 'solid 1px black',
          borderRadius: '100px',
          color: 'text',
          path: {
            fill: 'text'
          },
          ':hover': {
            backgroundColor: 'grey'
          }
        },
        margin: '0.5rem'
      }
    },
    '.gonationLogo': {
      width: '50%',
      filter: 'unset'
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    // variant: 'customVariants.gradientText',
    fontSize: ['2rem', '2.25rem', '2.5rem', '2.75rem', '3rem'],
    fontWeight: 'normal',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'left',
    marginBottom: '1rem',
    textTransform: 'uppercase',
    fontFamily: 'heading'
  },
  subtitle: {
    width: 'fit-content',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.5rem', '1.75rem', '2rem', '2rem'],
    marginBottom: '1rem',
    border: 'none',
    fontFamily: 'subheading',
    opacity: '0.8',
    fontWeight: '200',
    color: 'text'
  },
  text: {
    lineHeight: '1.75',
    p: {
      lineHeight: '1.75',
      fontSize: 'inherit',
      textAlign: 'left'
    }
  },

  sideBySide1: {
    padding: ['2rem', '', '2rem', '3rem'],
    '.lazyload-wrapper': {
      minHeight: ['', '', '50vh'],
      maxHeight: ['', '', '80vh'],
      justifyContent: 'flex-start'
    },

    '.content': {
      position: 'relative',
      alignItems: 'flex-start',
      margin: ['1rem 0rem', '', '0rem'],
      padding: ['2rem 0rem', '', '2rem 1rem 2rem 3rem']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1'
  },

  contactUsBar: {
    backgroundColor: '#ada89d78',
    '.title': {
      marginBottom: '0rem'
    }
  },

  gradientText: {
    background: 'linear-gradient(to bottom, #02ab4d 0%, #fded06 33%, #f5845b 66%, #f06085 100%)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent'
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    '.logoHero': {
      maxWidth: '200px',
      margin: '0rem auto 1.5rem 0rem'
    },
    '.hero_content_container': {
      // backgroundColor: '#ffffff85',
      marginLeft: '0rem',
      background: 'linear-gradient(0deg,rgba(255,255,255,1) 0%,rgb(255 255 255 / 64%) 80%,rgba(255,255,255,0) 100%)',
      paddingTop: ['5rem', '', '10rem'],
      pl: ['2rem', '', '4rem'],
      pr: ['2rem', '', '25%'],
      maxWidth: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      textAlign: 'left',
      left: '0rem',
      bottom: '0rem',
      top: 'unset',
      transform: 'unset',
      maxWidth: '100%'
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['1.6rem', '2.25rem', '2.5rem', '2.75rem', '3rem'],
      border: 'none',
      margin: '0rem 0rem 1rem',
      padding: '0rem',
      order: '2',
      color: '#dd9b00',
      textShadow: '0px 0px 8px #dadde4',
      textTransform: 'capitalize'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      width: '100%',
      order: '1'
    },
    '.text': {
      variant: 'customVariants.text',

      order: '3',

      p: {
        color: 'white'
      }
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageHeroShout: {
    display: 'none'
  },

  homepageAd: {
    variant: 'customVariants.sideBySide1',
    maxHeight: 'unset',
    flexDirection: ['column', 'column', 'column', 'column'],
    py: ['3rem', '3rem', '3rem', '3rem'],
    'div.content, div.lazyload-wrapper ': {
      width: ['100%', '100%', '100%', '100%'],
      maxHeight: 'unset',
      alignItems: 'center',
      textAlign: 'center',
      mb: '1.5rem',
      '.title': {
        textAlign: 'center'
      },
      img: {
        objectFit: 'contain'
      }
    }
  },

  homepageAbout: {
    minHeight: '70vh',
    padding: '2rem',
    '.content': {
      padding: ['2rem 0rem', '', '2rem']
    },
    '.title': {
      variant: 'customVariants.title',
      marginBottom: '1.5rem'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      marginBottom: '1.5rem',

      fontSize: ['1.25rem', '1.5rem', '1.5rem', '1.5rem']
    },

    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageBoxes: {
    backgroundColor: '#fff7e8',
    padding: ['10vh 1rem'],
    '.box': {
      margin: ['1.5rem 1rem', '', '1.5rem'],
      backgroundColor: 'primary',
      padding: '4rem 2rem',
      borderRadius: '1000px',
      width: '400px',
      height: '400px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      textAlign: 'center',
      '.title': {
        fontFamily: 'body2',
        mb: '1rem'
      },
      '.text': {
        '*': {
          fontSize: '0.9rem',
          color: 'white'
        }
      }
    }
  },

  homepageQuote1: {
    minHeight: '100vh',
    color: 'white',
    '.section': {
      margin: '0rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      maxWidth: 'unset',
      color: 'inherit'
    },

    '.title': {
      variant: 'customVariants.title',
      marginBottom: '1.5rem',
      fontSize: ['2rem', '2.5rem', '3rem', '4rem', '5rem'],
      color: 'inherit',
      margin: '0rem 0rem 3rem'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      marginBottom: '1.5rem',
      color: 'inherit'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'inherit',
      '> *': {
        color: 'inherit'
      }
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageQuote2: {
    variant: 'customVariants.homepageQuote1',
    color: 'text'
  },

  homepageServices: {
    variant: 'customVariants.sideBySide1'
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide2'
  },

  homepageContact: {
    variant: 'customVariants.homepageEvents',
    padding: ['25vh 1rem', '25vh 1rem', '25vh 1rem', '25vh 1rem'],
    color: 'white',
    '.section': {
      backgroundColor: '#3a3a3cb5',
      padding: '2rem 1rem'
    },
    '.title': {
      variant: 'customVariants.title',
      color: 'white'
    },
    '.text': {
      variant: 'customVariants.text',
      p: {
        color: 'white',
        textAlign: 'center'
      }
    }
  },

  homepageShout: {
    '.text': {
      color: 'white',
      variant: 'customVariants.subtitle',
      background: 'unset'
    },
    '.containerPopUp': {
      backgroundColor: 'primary',
      '.shoutContentContainerPopUp': {
        color: 'white'
      }
    }
  },

  homepageTestimonials: {
    padding: ['2rem 1rem', '', '4rem 1rem'],
    maxWidth: 'unset',
    '.menuSectionTitle': {
      variant: 'customVariants.title',
      border: 'none',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      mb: '2rem'
    },

    '.menuSectionDescription': {
      variant: 'customVariants.text',
      fontSize: '1rem'
    },

    '.menuItemNameContainer': {
      variant: 'customVariants.subtitle',
      order: '4',
      margin: '1rem auto 0rem',
      textAlign: 'center'
    },

    '.menuItemName': {
      fontSize: ['1rem', '1rem', '1rem', '1rem']
    },

    '.menuItemDescription': {
      variant: 'customVariants.text',
      color: 'primary',
      fontSize: '1rem',
      textAlign: 'center',
      lineHeight: '1.5',
      width: '100%',
      padding: ['0rem', '', '0rem 1rem'],
      margin: '0 auto 1rem'
    },

    '.slick-slider': {
      maxWidth: '767px',
      margin: '0 auto'
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    variant: 'customVariants.sideBySide1',
    // backgroundColor: 'black',
    'div.content': {
      padding: ['1rem', '2rem', '1rem 3rem 1rem 1rem', '1rem 4rem 1rem 1rem'],
      // width: ['100%', '', '70%', '70%'],
      // width: ['100%', '100%', '100%', '100%'],
      '.subtitle': {
        // color: 'white',
      },
      // color: 'white',
      '.text': {
        '> *': {
          // color: 'white',
        }
      }
    },
    'div.lazyload-wrapper': {
      // width: ['100%', '', '35%', '35%'],
      // display: 'none',
    }
  },
  videoSlider: {
    padding: ['10vh 0rem 15vh', '', '15vh 0rem 25vh'],
    '.title': {
      variant: 'text.title',
      fontSize: ['2.2rem', '2.5rem', '3rem', '4rem', '4rem']
    },
    '.subtitle': { variant: 'text.subtitle' },
    '.text': { variant: 'text.text' },
    '.linksContainer': { order: '4' },
    '> .mainTextContainer': {
      display: 'none'
    },
    backgroundColor: 'backgroundSecondary',
    color: 'white',
    '.slideBox': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '.textContent': {
        padding: '2rem',
        maxWidth: '800px'
      }
    },
    iframe: {
      width: ['100%', '', '', '800px'],
      height: ['60vw', '', '', '400px']
    },

    '.slick-dots': {
      bottom: '-35px'
    },
    '.slick-arrow': {
      position: 'absolute',
      border: 'solid 1px',
      width: '25px',
      height: '25px',
      top: '65%',
      padding: '0.3rem',
      color: 'secondary',
      borderColor: 'secondary',
      ':hover': {
        borderColor: 'white'
      }
    },

    '.slick-next': {
      right: '0rem'
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  // menu: {},
  servicesPage: {
    '.menuContainer': {
      width: '100%'
    },
    '.allInContainer': {
      width: ['100%', '', ''],
      margin: ['0rem', '', '1rem'],
      border: 'solid 1px lightgrey',

      flexGrow: '1',
      '.menuItemsContainer': {},
      '.menuItemContainerImgActive': {
        // width: '100%'
      },
      '.menuItemContentContainer': {},
      '.menuSectionTitle': {
        textAlign: 'left',
        padding: '1.5rem',
        borderBottom: '1px solid lightgrey'
      },
      '.menuSectionDescription': {
        textAlign: 'left',
        padding: '0rem 3rem',
        mb: '2rem'
      },
      '.menuItemsContainer': {
        padding: '1rem 1.5rem'
      },
      '.menuItemName': {
        fontWeight: 'normal',
        fontSize: '1.25rem',
        margin: '0rem 0rem 0.5rem',
        '::before': {
          content: "'➤'",
          margin: '0.5rem'
        }
      },
      '.menuItemDescription': {
        opacity: '0.7'
      }
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    marginTop: ['3rem', '4rem', '6rem'],
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.albumName': {
      display: 'none'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },
    '.eventItemContainer': {
      padding: ['0.5rem', '1rem', '1.5rem', '2rem', '3rem'],
      flexDirection: ['column', 'column', 'row', 'row', 'row'],
      margin: '1.5rem 0rem 1.5rem',
      boxShadow: '2px 2px 10px lightgrey'
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: ['100%', '100%', '50%', '40%', '35%'],
      // height: '100%',
      maxHeight: 'unset',
      objectFit: 'contain',
      objectPosition: 'top',
      cursor: 'pointer',
      transition: 'all ease-in-out 0.8s',
      ':hover': {
        // opacity: '0.8',
        transform: 'scale(1.05)',
        filter: 'brightness(0.6)'
      }
      // border: 'solid 1px lightgrey',
    },
    '.eventItemContent': {
      width: ['100%', '100%', '50%', '60%', '65%'],
      padding: ['1rem', '1.5rem', '2rem', '3rem'],
      marginTop: ['1.5rem', '', '0rem'],
      paddingTop: ['1rem', '', '0rem'],
      borderTop: ['1px solid', '1px solid', 'none', ''],
      borderColor: ['secondary', 'secondary']
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemTitle': {
      lineHeight: ['1.5', '1.5', '1.5', '1.75', '1.75']
    },

    '.eventItemDescription': {
      maxHeight: 'unset',
      overflowY: 'scroll',
      fontSize: ['1rem', '', '', '1.1rem', '1.2rem'],
      lineHeight: ['1.5', '1.5', '1.5', '1.75', '1.75'],
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    },
    '.eventCTABtns': {
      marginTop: '1rem',
      a: {
        variant: 'buttons.primary',
        textDecoration: 'none'
      }
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem']
    },
    '.subtitle': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '::after': {
        content: "'Telehealth is available'",
        fontSize: '1.1rem',
        mt: '1rem',
        opacity: '0.8'
      }
    },
    '.text': {
      color: 'text'
    },
    backgroundPosition: 'center center',
    color: 'text',
    backgroundColor: 'secondary',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'text',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'text',
      '::placeholder': {
        color: 'text'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      height: 'unset',
      width: 'auto'
    }
  },

  locationMap: {
    order: '3',
    '.content_container': {
      padding: '0rem'
    },
    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      width: '100%',
      backgroundColor: 'secondary',
      padding: '0.5rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
      textAlign: 'center',
      alignItems: 'center'
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem'
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem']
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    }
  },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.textContent': {
      backgroundColor: 'black'
    },
    '.imageContainer': {
      backgroundColor: '#8a7d74',
      img: {
        objectFit: 'contain',
        padding: '1rem'
      }
    }
  },

  pressPage: {
    backgroundColor: 'background'
  },

  // ? ========================
  // ? =======  blog   ========
  // ? ========================
  blogMenu: {
    '.boxesContainer': {
      minHeight: '300px'
    },
    padding: ['1rem', '1rem', '1rem', '1rem'],
    '.box': {
      backgroundColor: 'lightgrey'
    }
  },

  blogPage: {},

  // ? ========================
  // ? =====  services   ======
  // ? ========================

  servicesMenu: {
    '.menuSectionTitle': {
      display: 'none'
    },
    '.menuSectionDescription': {
      padding: '2rem',
      backgroundColor: 'primary',
      margin: '0rem 0rem 2rem',
      color: 'white'
    },
    '.menuItemContainer, .menuItemContainerImgActive': {
      width: ['100%', 'calc(50% - 1rem)', '', 'calc(25% - 1rem)'],
      margin: ['', '0.5rem', '', '0.5rem']
    },
    '.menuItemImageContainer': {
      display: 'block',
      padding: '0.25rem',
      border: 'solid 1px',
      borderColor: 'primary',
      borderRadius: '0px',
      img: {
        padding: '0.25rem'
      }
    },
    '.itemImageFill': {
      paddingBottom: '100%'
    },
    '.menuItemContentContainer': {
      zIndex: '1',
      '::before': {
        content: "''",
        border: 'solid 1px',
        borderColor: 'grey',
        width: 'calc(100% + 0.5rem)',
        height: 'calc(100% + 0.5rem)',
        position: 'absolute',
        left: '-0.25rem',
        top: '-0.25rem',
        zIndex: '-1'
      },
      position: 'relative',
      top: '-3rem',
      padding: '1rem',
      backgroundColor: 'grey',
      width: '90%',
      textAlign: 'center',
      margin: '0rem auto',
      backgroundColor: 'rgba(250, 248, 247, 0.9)',
      padding: '0.5rem'
    },
    '.menuItemName': {
      borderBottom: 'dashed 1px',
      paddingBottom: '0.5rem'
    },

    '.menuItemDescription': {
      fontSize: '0.8rem',
      width: '100%',
      textTransform: 'capitalize'
    }
  }
}
